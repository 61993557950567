<script>
export default {
  name: 'MaterialCheckbox',
  props: {
    label: {
      type: String
    },
    value: {
      default: ''
    },
    name: {
      type: String
    },
    error: {
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      default: 'text'
    },
    hint: {
      default: null
    },
    mask: {
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    phalconMode: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    value (newValue) {
      this.inputValue = this.sanitizeValue(newValue)
    }
  },
  data () {
    return {
      inputValue: this.sanitizeValue(this.value)
    }
  },
  methods: {
    onInput (value) {
      this.$emit('input', value)
    },
    sanitizeValue (newValue) {
      if (this.phalconMode) {
        if (newValue === "1") {
          newValue = 1
        } else if (newValue === "0") {
          newValue = 0
        }
      }

      return newValue
    }
  }
}
</script>

<template>
  <div class="material-checkbox">
    <md-checkbox v-if="phalconMode" v-model="inputValue" @change="onInput" :disabled="disabled" :true-value="1" :false-value="0" :readonly="readonly">{{ label }}</md-checkbox>
    <md-checkbox v-else v-model="inputValue" @change="onInput" :disabled="disabled" :readonly="readonly">{{ label }}</md-checkbox>
    <div v-if="error" class="md-error">{{ error }}</div>
    <div v-if="hint" class="md-helper-text">{{ hint }}</div>
  </div>
</template>

<style>
  .material-checkbox {
    position: relative;
  }

  .material-checkbox .md-helper-text {
    height: 20px;
    position: absolute;
    bottom: -2px;
    font-size: 12px;
    transition: .3s cubic-bezier(.4,0,.2,1);
    color: rgba(0,0,0,0.54);
  }
</style>
