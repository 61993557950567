import Vue from 'vue'
import {
  MdSwitch,
  MdField,
  MdButton,
  MdRadio,
  MdDatepicker,
  MdDialog,
  MdDivider,
  MdCheckbox,
  MdContent,
  MdMenu,
  MdList,
  MdProgress,
  MdEmptyState
} from 'vue-material/dist/components'
import 'vue-material/dist/vue-material.min.css'
// import 'vue-material/dist/theme/default.css'
import './material-overrides.scss'


Vue.use(MdSwitch)
Vue.use(MdField)
Vue.use(MdButton)
Vue.use(MdRadio)
Vue.use(MdDatepicker)
Vue.use(MdDialog)
Vue.use(MdDivider)

Vue.use(MdCheckbox)
Vue.use(MdContent)
Vue.use(MdMenu)
Vue.use(MdList)
Vue.use(MdProgress)
Vue.use(MdEmptyState)
