var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-dialog",
    {
      style: _vm.styles,
      attrs: { "md-active": _vm.visible },
      on: {
        "update:mdActive": function($event) {
          _vm.visible = $event
        },
        "update:md-active": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("md-dialog-title", [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-window-content" },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _c(
        "md-dialog-actions",
        [
          _vm._t("actions", function() {
            return [
              _c(
                "md-button",
                { staticClass: "md-primary", on: { click: _vm.hide } },
                [_vm._v("Close")]
              )
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }