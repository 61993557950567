export default {
  $_veeValidate: {
    value () {
      return this.inputValue ? this.inputValue.toString() : '';
    },
    name () {
      const prefix = this.$attrs['data-vv-scope'] || ''
      const name = this.name || this.label

      if (prefix) {
        return `${prefix}.${name}`
      }

      return name
    }
  },
  props: ['displayError'],
  computed: {
    error () {
      if (this.displayError) {
        return this.displayError
      }

      if (!this.errors) {
        return null
      }

      const fieldName = this.$attrs['data-vv-name'] || this.name || this.label
      const scope = this.$attrs['data-vv-scope'] || null
      const error = this.errors.items.find(error => error.field == fieldName || error.field == `${scope}.${fieldName}`)
      if (!error) {
        return null
      }
      return error.msg
    }
  }
}
