<script>
export default {
  name: 'TwoColumnInput'
}
</script>

<template>
  <div class="md-layout md-gutter">
    <div class="md-layout-item md-small-size-100">
      <slot name="left"/>
    </div>
    <div class="md-layout-item md-small-size-100">
      <slot name="right"/>
    </div>
  </div>
</template>
