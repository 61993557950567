<script>
import axios from 'axios'

export default {
  name: 'CouponForm',
  props: ['visible', 'packageCode'],
  data () {
    return {
      code: null,
      error: null
    }
  },
  watch: {
    code (newCode) {
      this.error = null
      if (!this.code && newCode) {
        gtag('event', `fill-a-form-code`, {type: 'fill'})
      }
    }
  },
  methods: {
    validateCoupon () {
      axios.get(`/coupon/ajaxload/${this.code}/${this.packageCode}`)
        .then(response => {
          gtag('event', `press-a-button-apply`, {type: 'press'})
          if (!response.data) {
            this.error = 'Coupon is not valid.'
            return
          }

          this.$emit('coupon', response.data)
        })
    },
    hide () {
      this.$emit('hide')
      gtag('event', `press-a-button-cancel`, {type: 'press'})
    }
  }
}
</script>

<template>
  <modal-window :visible="visible" @hide="$emit('hide')" title="Enter coupon">
    <material-input label="CODE" v-model="code" v-validate="'required'" :display-error="error"/>

    <div slot="actions">
      <md-button @click="validateCoupon">Apply</md-button>
      <md-button @click="hide">Cancel</md-button>
    </div>
  </modal-window>
</template>
