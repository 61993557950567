<script>
export default {
  name: 'ThreeColumnInput'
}
</script>

<template>
  <div class="md-layout">
    <div class="md-layout-item" style="margin-right: 5px">
      <slot name="left"/>
    </div>
    <div class="md-layout-item" style="margin-left: 5px">
      <slot name="center"/>
    </div>
    <div class="md-layout-item" style="margin-left: 5px">
      <slot name="right"/>
    </div>
  </div>
</template>
