function pack (form, key, item) {
  if (Array.isArray(item)) {
    let i = 0;
    item.forEach(nestedItem => {
      pack(form, `${key}[${i}]`, nestedItem)
      i++
    })
  } else if (item.name && item.lastModified && item.size) { // we have a file
    form.append(key, item)
  } else if (isObject(item)) {
    for (let innerKey in item ) {
      if (!item.hasOwnProperty(innerKey)) {
        continue
      }
      const nestedItem = item[innerKey]
      pack(form, `${key}[${innerKey}]`, nestedItem)
    }
  } else {
    form.append(key, item)
  }
}

export function objectToFormData (object) {
  const form = new FormData()

  for (let key in object){
    if (!object.hasOwnProperty(key)) {
      continue;
    }

    if (object[key] !== null) {
      pack(form, key, object[key])
    }
  }

  return form
}

function isObject(val) {
  if (val === null) { return false;}
  return ( (typeof val === 'function') || (typeof val === 'object') );
}
