<template>
  <modal-window :visible="visible" @hide="$emit('hide')" title="Edit Address">
    <div style="min-width: 30%">
      <material-input v-model="contact[addressType + '_street1']" label="Street"></material-input>
      <material-input v-if="typeof contact[addressType + '_street2'] != 'undefined'" v-model="contact[addressType + '_street2']" label="Street Line 2"></material-input>
      <material-input v-model="contact[addressType + '_city']" label="City"></material-input>
      <material-input v-model="contact[addressType + '_state']" label="State"></material-input>
      <material-input v-model="contact[addressType + '_zip']" label="Zip"></material-input>
      <material-input v-if="typeof contact[addressType + '_country'] != 'undefined'" v-model="contact[addressType + '_country']" label="Country"></material-input>
    </div>

    <span slot="actions">
      <md-button @click="$emit('hide')" class="md-primary">Save</md-button>
    </span>
  </modal-window>
</template>
<script>
import ModalWindow from '../../Util/ModalWindow'
export default {
  name: 'AddressEditor',
  components: {
    ModalWindow
  },
  props: ['addressType', 'contact', 'visible']
}
</script>
