import 'material-design-lite/material.min.css'
import 'material-design-lite/material.min'


import Vue from 'vue'
import VueMask from 'v-mask'
import Order from './Order/Order'
import VeeValidate from 'vee-validate'
import MaterialInput from '../../member/webpack/src/UI/MaterialInput'
import MaterialInputDual from '../../member/webpack/src/UI/MaterialInputDual'
import MaterialSelect from '../../member/webpack/src/UI/MaterialSelect'
import MaterialTextarea from '../../member/webpack/src/UI/MaterialTextarea'
import MaterialCheckbox from '../../member/webpack/src/UI/MaterialCheckbox'
import ModalWindow from '../../member/webpack/src/Util/ModalWindow'


import './material'
import '../assets/scss/frontend.scss'

Vue.use(VueMask)
Vue.use(VeeValidate)


Vue.component('material-input', MaterialInput)
Vue.component('material-input-dual', MaterialInputDual)
Vue.component('material-select', MaterialSelect)
Vue.component('material-textarea', MaterialTextarea)
Vue.component('material-checkbox', MaterialCheckbox)
Vue.component('modal-window', ModalWindow)

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('order-page')) {
    window.routedApp = new Vue({
      name: 'OrderPage',
      data: {
        snackbarMessage: null,
        snackbarShow: false,
      },
      components: {Order},
      methods: {
        showMessage (message) {
          this.snackbarMessage = message
          this.snackbarShow = true
        }
      }
    }).$mount('#order-page')
  }
})

