var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "material-checkbox" },
    [
      _vm.phalconMode
        ? _c(
            "md-checkbox",
            {
              attrs: {
                disabled: _vm.disabled,
                "true-value": 1,
                "false-value": 0,
                readonly: _vm.readonly
              },
              on: { change: _vm.onInput },
              model: {
                value: _vm.inputValue,
                callback: function($$v) {
                  _vm.inputValue = $$v
                },
                expression: "inputValue"
              }
            },
            [_vm._v(_vm._s(_vm.label))]
          )
        : _c(
            "md-checkbox",
            {
              attrs: { disabled: _vm.disabled, readonly: _vm.readonly },
              on: { change: _vm.onInput },
              model: {
                value: _vm.inputValue,
                callback: function($$v) {
                  _vm.inputValue = $$v
                },
                expression: "inputValue"
              }
            },
            [_vm._v(_vm._s(_vm.label))]
          ),
      _vm._v(" "),
      _vm.error
        ? _c("div", { staticClass: "md-error" }, [_vm._v(_vm._s(_vm.error))])
        : _vm._e(),
      _vm._v(" "),
      _vm.hint
        ? _c("div", { staticClass: "md-helper-text" }, [
            _vm._v(_vm._s(_vm.hint))
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }