var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-field",
    { class: _vm.classes },
    [
      _c("label", [
        _vm._v(_vm._s(_vm.label)),
        _vm.required ? _c("span", [_vm._v("*")]) : _vm._e()
      ]),
      _vm._v(" "),
      _c("md-textarea", {
        attrs: { placeholder: _vm.placeholder, disabled: _vm.disabled },
        on: { input: _vm.onInput },
        model: {
          value: _vm.inputValue,
          callback: function($$v) {
            _vm.inputValue = $$v
          },
          expression: "inputValue"
        }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "md-error" }, [_vm._v(_vm._s(_vm.error))]),
      _vm._v(" "),
      _vm.hint
        ? _c("span", { staticClass: "md-helper-text" }, [
            _vm._v(_vm._s(_vm.hint))
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }