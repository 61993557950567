<script>
import VeeValidateMixin from './VeeValidateMixin'

export default {
  name: 'MaterialInput',
  mixins: [ VeeValidateMixin ],
  props: {
    label: {
      type: String
    },
    value: {
      default: ''
    },
    name: {
      type: String
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      default: 'text'
    },
    hint: {
      default: null
    },
    mask: {
      default: null
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value (newValue) {
      this.inputValue = newValue
    }
  },
  data () {
    return {
      inputValue: this.value
    }
  },
  methods: {
    onInput (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<template>
  <md-field :class="{ 'md-invalid': error }" >
    <label>{{ label }}<span v-if="required">*</span></label>
    <md-input v-if="mask" v-model="inputValue" :placeholder="placeholder" @input="onInput" :disabled="disabled" v-mask="mask" :name="name || label" :type="type"></md-input>
    <md-input v-else v-model="inputValue" :placeholder="placeholder" @input="onInput" :disabled="disabled" :name="name || label" :type="type"></md-input>
    <slot/>
    <span class="md-error">{{ error || errors.first(name || label) }}</span>
    <span v-if="hint" class="md-helper-text">{{ hint }}</span>
  </md-field>
</template>
