<script>
import VeeValidateMixin from './VeeValidateMixin'

export default {
  name: 'MaterialTextarea',
  mixins: [ VeeValidateMixin ],
  props: {
    label: {
      type: String
    },
    value: {
      default: ''
    },
    name: {
      type: String
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      default: 'text'
    },
    hint: {
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    mdAutogrow: {

    }
  },
  watch: {
    value (newValue) {
      this.inputValue = newValue
    }
  },
  data () {
    return {
      inputValue: this.value
    }
  },
  computed: {
    classes () {
      const classes = this.$attrs.class || {}
      classes['md-invalid'] = !!this.error

      return classes
    }
  },
  methods: {
    onInput (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<template>
  <md-field :class="classes">
    <label>{{ label }}<span v-if="required">*</span></label>
    <md-textarea v-model="inputValue" :placeholder="placeholder" @input="onInput" :disabled="disabled"></md-textarea>
    <span class="md-error">{{ error }}</span>
    <span v-if="hint" class="md-helper-text">{{ hint }}</span>
  </md-field>
</template>

<style>
  .md-field.fullheight .md-textarea {
    height: auto;
    max-height: unset;
    flex: 1;
  }
</style>