var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-field",
    { staticClass: "md-input-dual", class: { "md-invalid": _vm.error } },
    [
      _c("label", [_vm._v(_vm._s(_vm.label))]),
      _vm._v(" "),
      _vm._t("left"),
      _vm._v(" "),
      _vm._t("right"),
      _vm._v(" "),
      _vm.hint
        ? _c("span", { staticClass: "md-helper-text" }, [
            _vm._v(_vm._s(_vm.hint))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("span", { staticClass: "md-error" }, [_vm._v(_vm._s(_vm.error))])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }