<script>
import MaterialInput from '../../../member/webpack/src/UI/MaterialInput'
import MaterialInputDual from '../../../member/webpack/src/UI/MaterialInputDual'
import TwoColumnInput from '../../../member/webpack/src/UI/TwoColumnInput'
import ThreeColumnInput from '../../../member/webpack/src/UI/ThreeColumnInput'
import AddressEditor from '../../../member/webpack/src/Contact/components/AddressEditor'

import jQuery from "jquery"
import Card from 'card'
import moment from 'moment'
import axios from 'axios'
import CouponForm from './CouponForm'
import { objectToFormData } from '../../../member/webpack/src/Util/ObjectToFormData'
import { Validator } from 'vee-validate'

window.jQuery = jQuery;


export default {
  name: 'Order',
  components: {
    CouponForm,
    AddressEditor,
    TwoColumnInput,
    ThreeColumnInput,
    MaterialInput,
    MaterialInputDual
  },
  data () {
    return {
      member: {
        name: '',
        email: '',
        password: '',
        phone: '',
        billing_street1: '',

        billing_city: '',
        billing_state: '',
        billing_zip: '',
        billing_phone: '',

        shipping_street1: '',

        shipping_city: '',
        shipping_state: '',
        shipping_zip: '',

        billing_type: window.packageData.annual_price ? 'annual' : 'monthly',
        card_nonce: '',
      },

      packageData: window.packageData,
      planCode: window.packageData.code.toLocaleLowerCase(),

      addressEditorVisible: false,
      editAddressType: 'billing',
      billingAddressDifferent: false,
      showCouponForm: false,
      coupon: null,
      success: false,
      errorMessage: null,
      submitting: false,
      numberConfirmed: false,
      code: '',
      codeError: null,
      confirmNumberVisible: false,
    }
  },
  computed: {
    trialDays () {
      if (this.coupon && this.coupon.trial) {
        return this.coupon.trial
      }

      return this.packageData.trial_days
    },
    billingAddressFull () {
      return this.getFullAddress('billing')
    },
    shippingAddressFull () {
      return this.getFullAddress('shipping')
    },
    billingDate () {
      if (this.packageData.trial_days) {
        return moment().add(this.trialDays, 'd').format('M/D/YY')
      }

      return moment().format('M/D/YY')
    },

    monthlyPrice () {
      if (!this.coupon) {
        return this.packageData.monthly
      }

      if (this.coupon.discount) {
        return this.packageData.monthly - this.packageData.monthly * this.coupon.discount / 100
      }

      if (this.coupon.fix_price) {
        return this.coupon.fix_price
      }

      return this.packageData.monthly
    },
    annualPrice () {
      if (!this.coupon) {
        return this.packageData.annual_price
      }

      if (this.coupon.annual_discount) {
        return this.packageData.annual_price - this.packageData.annual_price * this.coupon.annual_discount / 100
      }

      if (this.coupon.annual_fix_price) {
        return this.coupon.annual_fix_price
      }

      return this.packageData.annual_price
    },
    orderTotal () {
      if (this.trialDays > 0) {
        return 0
      }

      if (this.member.billing_type == 'annual') {
        return this.annualPrice
      } else {
        return this.monthlyPrice
      }
    },
    saveAmount () {
      if (this.monthlyPrice == this.packageData.monthly) {
        return Math.round(this.monthlyPrice * 12 - this.annualPrice)
      }

      const couponMonths = this.coupon.valid_months || 1
      return Math.round(this.monthlyPrice * (couponMonths) + this.packageData.monthly * (12 - couponMonths) - this.annualPrice)
    }
  },
  created () {
    Validator.extend('member_email', {
      getMessage: () => `Email is already used.`,
      validate: value => new Promise(resolve => {
        axios.post('/order/validateEmail', {email: this.member.email})
        .then(response => resolve({ valid: response.data.valid }))
      })
    })
  },
  mounted () {
    this.initSquareForm();

    gtag('event', 'press', {event_category: `press-a-button-sign-up-${this.planCode}-plans`})
  },
  watch: {
    'member.name': function (newValue) {
      this.member.card_holder_name = newValue
      const event = new Event('change')
      this.$el.querySelector('input[name="name"]').dispatchEvent(event)
    },
    'member.card_expiration': function (newValue) {
      if (this.member.card_expiration.length == 0 && newValue) {
        gtag('event', 'fill', {event_category: `fill-a-form-${this.planCode}-plans`})
      }
    },
    'member.billing_type': function (newValue) {
      // console.log('new billing type', newValue)
      gtag('event', 'press', {event_category: `press-a-button-${newValue}-${this.planCode}`})
    }
  },
  methods: {
    editBillingAddress () {
      this.editAddressType = 'billing'
      this.addressEditorVisible = true
    },
    editShippingAddress () {
      this.editAddressType = 'shipping'
      this.addressEditorVisible = true
    },
    getFullAddress (name) {
      let result = ''
      const addressParts = [
        'street1',
        'street2',
        'city',
        'state',
        'zip',
        'country'
      ]

      addressParts.forEach((part) => {
        const partName = name + '_' + part
        if (this.member[partName]) {
          if (result.length) {
            result += ', '
          }

          result += this.member[partName]
        }
      })

      return result
    },
    async confirmNumberStep1 () {
      this.codeError = null
      const result = await this.$validator.validateAll()
      if (result) {
        const response = await axios.post('/order/confirmNumber', { number: this.member.phone })
        if (response.data.error) {
          this.errorMessage = response.data.error
        } else if (response.data.ok) {
          this.confirmNumberVisible = true
        } else {
          this.errorMessage = 'An error occurred'
        }
      }
    },
    async confirmNumberStep2 () {
      this.codeError = null
      const response = await axios.post('/order/confirmNumberCode', { number: this.member.phone, code: this.code })
      if (response.data.ok) {
        this.confirmNumberVisible = false
        this.numberConfirmed = true
      } else if (response.data.error) {
        this.codeError = response.data.error
      } else {
        this.codeError = 'An error occurred'
      }
    },
    submit () {
      this.$validator.validateAll().then(result => {
        if (!result) {
          return
        }
        this.submitting = true
        this.squareForm.requestCardNonce()
      })
    },
    completeOrder () {
      const request = {
        ...this.member,
        package_id: this.packageData.id,
        couponCode: this.coupon ? this.coupon.code : null,
        billing_address_different: this.billingAddressDifferent ? 1 : 0,
        code: this.code,
      }

      axios.post('/order/orderAjax', objectToFormData(request))
        .then(response => {
          if (response.data.error) {
            this.errorMessage = response.data.error
            this.submitting = false
          } else if (!response.data.ok) {
            this.errorMessage = "An error occurred."
            this.submitting = false
          } else {
            this.success = true
            gtag('event', 'send', {
              event_category: `send-a-form-${this.planCode}`,
              value: response.data.amount,
              currency: 'USD',
              transaction_id: response.data.id,
            })

            gtag('event', 'purchase', {
              "transaction_id": response.data.id,
              "value": response.data.amount,
              "currency": "USD",
            })
          }
        })
    },
    applyCoupon (coupon) {
      this.showCouponForm = false
      this.coupon = coupon
    },
    goToMemberArea () {
      window.location.href = '/member/virtual-number/add'
    },
    showCouponFormAndGtag () {
      this.showCouponForm = true
      gtag('event', 'press', {event_category: `press-a-button-i-have-a-coupon`})
    },
    cardNonceResponseReceived (errors, nonce, cardData) {
      if (errors) {
        // Log errors from nonce generation to the browser developer console.
        console.error('Encountered errors:');
        errors.forEach(function (error) {
          console.error('  ' + error.message);
        });

        alert(errors.map(e => e.message).join("\n"));
        this.loading = false
        return;
      }

      this.member.card_last_digits = cardData.last_4
      this.member.card_type = cardData.card_brand
      this.member.cardNonce = nonce
      this.member.card_zip = cardData.billing_postal_code
      this.completeOrder()
    },
    initSquareForm () {
      // eslint-disable-next-line no-undef
      this.squareForm = new SqPaymentForm({
        // Initialize the payment form elements

        applicationId: window.squareAppId,
        autoBuild: false,

        // Initialize the credit card placeholders
        card: {
          elementId: 'sq-card',
        },
        // SqPaymentForm callback functions
        callbacks: {
          cardNonceResponseReceived: this.cardNonceResponseReceived,
        }
      })
      this.squareForm.build()
    },
  }
}
</script>

<template>
  <div class="order-form-container">

    <div class="order-form-wrapper login__container">
      <div class="md-title">
        Selected package: {{ packageData.code }}
      </div>

      <two-column-input>
        <material-input slot="left" v-validate="'required'" label="Your Name" v-model="member.name"/>
        <material-input slot="right" v-validate="'required'" mask="(###) ###-####" label="Phone" v-model="member.phone"/>
      </two-column-input>

      <two-column-input>
        <material-input slot="left" v-validate="'required|email|member_email'" data-vv-delay="500" label="Email" v-model="member.email"/>
        <material-input slot="right" v-validate="'required'" label="Password" v-model="member.password" type="password"/>
      </two-column-input>

      <material-input-dual v-validate="'required'" label="Address" v-model="shippingAddressFull">
        <md-input slot="left" :readonly="true" :value="shippingAddressFull" @focus="editShippingAddress"/>
        <span slot="right">
          <i class="material-icons messenger_feedback_icon" title="Edit" @click="editShippingAddress">edit</i>
        </span>
      </material-input-dual>

      <div id="sq-card"></div>

      <md-switch :value="true" v-model="billingAddressDifferent">My card has a different billing address</md-switch>

      <material-input-dual v-validate="'required'"
                           label="Card Billing Address"
                           v-if="billingAddressDifferent"
                           v-model="billingAddressFull">
        <md-input slot="left" :readonly="true" :value="billingAddressFull" @focus="editBillingAddress"/>
        <span slot="right">
          <i class="material-icons messenger_feedback_icon" title="Edit" @click="editBillingAddress">edit</i>
        </span>
      </material-input-dual>
      <material-input v-if="billingAddressDifferent"
                      v-model="member.billing_phone"
                      mask="(###) ###-####"
                      v-validate="'required'"
                      label="Card Billing Phone"/>
      <md-divider/>

      <div class="md-subheading" style="padding-top: 10px">Billing type</div>

      <div v-if="annualPrice > 0">
        <md-radio v-model="member.billing_type" value="annual">
          Annual:
          <span v-if="!coupon || !coupon.annual_discount">
            <b>${{ annualPrice }}</b>.
          </span>
          <span v-else>
            <b>${{ annualPrice }}</b> now and <b>${{ packageData.annual_price}}</b> from next year.
          </span>
          You save <b>${{ saveAmount }}</b>.
        </md-radio>
      </div>
      <div>
        <md-radio v-model="member.billing_type" value="monthly">
          Monthly:
          <span v-if="monthlyPrice == packageData.monthly">
            <b>${{ monthlyPrice }}</b>
          </span>
          <span v-else>
            <b>${{ monthlyPrice }}</b> now and <b>${{ packageData.monthly}}</b> after {{ coupon.valid_months || 1 }} months
          </span>
        </md-radio>
      </div>
      <div v-if="trialDays > 0">
        <b>{{ trialDays }}</b> day trial. You will be billed on <b>{{ billingDate }}</b>. Cancel any time in your account settings.
      </div>
      <br/>
      <div v-if="coupon">
        Coupon: <b>{{ coupon.code }}</b>
        <ul style="margin-left: 25px">
          <li v-if="coupon.discount">{{ coupon.discount }}% off monthly <span v-if="coupon.valid_months">for first {{ coupon.valid_months }} months</span></li>
          <li v-if="coupon.annual_discount">{{ coupon.annual_discount }}% off annual</li>
          <li v-if="coupon.fix_price">${{ coupon.fix_price }} fixed price <span v-if="coupon.valid_months">for first {{ coupon.valid_months }} months</span></li>
          <li v-if="coupon.trial">{{ coupon.trial }} day trial</li>
        </ul>
      </div>

      <div style="text-align: right">
        <md-button class="md-raised" v-if="!coupon" @click="showCouponFormAndGtag">I have a coupon!</md-button>
        <md-button
          v-if="numberConfirmed"
          class="md-raised md-primary"
          :disabled="submitting"
          @click="submit"
        >
          Pay ${{ orderTotal }} and proceed
        </md-button>
        <md-button
                v-else
                class="md-raised md-primary"
                :disabled="submitting"
                @click="confirmNumberStep1"
        >
          Confirm phone number
        </md-button>
      </div>
    </div>

    <address-editor :contact="member"
                    :visible="addressEditorVisible"
                    @hide="addressEditorVisible = false"
                    :address-type="editAddressType"/>


    <coupon-form :visible="showCouponForm"
                 @hide="showCouponForm = false"
                 :package-code="packageData.code"
                 @coupon="applyCoupon"/>

    <modal-window :visible="!!errorMessage" @hide="errorMessage = null">
      <md-empty-state md-icon="error_outline"
                      class="md-accent"
                      :md-label="errorMessage"/>
      <div slot="actions">
        <md-button @click="errorMessage = null">OK</md-button>
      </div>
    </modal-window>

    <modal-window :visible="confirmNumberVisible" @hide="confirmNumberVisible = false" title="Enter confirmation code">
      <material-input label="CODE" v-model="code" v-validate="'required'" :display-error="codeError"/>

      <div slot="actions">
        <md-button @click="confirmNumberStep2">Confirm</md-button>
        <md-button @click="confirmNumberVisible = false">Cancel</md-button>
      </div>
    </modal-window>

    <modal-window :visible="success" @hide="goToMemberArea">
      <md-empty-state md-icon="done"
                      class="md-primary"
                      md-label="Success"
                      md-description="Now lets choose your first virtual number."
      />
      <div slot="actions">
        <md-button @click="goToMemberArea">Proceed</md-button>
      </div>
    </modal-window>
  </div>
</template>

<style>
  .messenger_feedback_icon {
    cursor: pointer;
    color: var(--md-theme-default-icon-on-background, rgba(0, 0, 0, 0.54));
  }

  .order-form-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 5px;
    padding-bottom: 80px;
  }

  .order-form-wrapper {
    padding: 5px;

  }

  @media screen and (min-width: 720px) {
    .order-form-wrapper {
      padding: 20px;
    }

    .order-form-container {
      padding: 40px;
      padding-bottom: 80px;
    }
  }
</style>
