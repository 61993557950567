<script>
import VeeValidateMixin from './VeeValidateMixin'

export default {
  name: 'MaterialInputDual',
  mixins: [ VeeValidateMixin ],
  props: {
    label: {
      type: String
    },
    value: {},
    hint: {
      default: null
    }
  },
  data () {
    return {
      inputValue: this.value
    }
  },
  watch: {
    value (newValue) {
      this.inputValue = newValue
    }
  }
}
</script>

<template>
  <md-field class="md-input-dual" :class="{ 'md-invalid': error }" >
    <label>{{ label }}</label>
    <slot name="left"></slot>
    <slot name="right"></slot>
    <span v-if="hint" class="md-helper-text">{{ hint }}</span>
    <span class="md-error">{{ error }}</span>
  </md-field>
</template>

<style>
  .md-input-dual .md-checkbox {
    margin: 0;
  }
</style>
