<script>
export default {
  name: 'ModalWindow',
  props: {
    title: {
      type: String
    },
    visible: {
      type: Boolean,
      default: false
    },
    zIndex: {
      default: '',
    }
  },
  watch: {
    visible (newValue) {
      if (!newValue) {
        this.$emit('hide')
      }
    }
  },
  computed: {
    styles () {
      const styles = {}
      if (this.zIndex) {
        styles['z-index'] = this.zIndex
      }

      return styles
    }
  },
  methods: {
    hide () {
      this.$emit('hide')
    }
  }
}
</script>

<template>
  <md-dialog :md-active.sync="visible" :style="styles">
    <md-dialog-title>{{ title }}</md-dialog-title>
    <div class="modal-window-content">
      <slot></slot>
    </div>
    <md-dialog-actions>
      <slot name="actions">
        <md-button class="md-primary" @click="hide">Close</md-button>
      </slot>
    </md-dialog-actions>
  </md-dialog>
</template>
