var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-window",
    {
      attrs: { visible: _vm.visible, title: "Enter coupon" },
      on: {
        hide: function($event) {
          return _vm.$emit("hide")
        }
      }
    },
    [
      _c("material-input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "required",
            expression: "'required'"
          }
        ],
        attrs: { label: "CODE", "display-error": _vm.error },
        model: {
          value: _vm.code,
          callback: function($$v) {
            _vm.code = $$v
          },
          expression: "code"
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "actions" }, slot: "actions" },
        [
          _c("md-button", { on: { click: _vm.validateCoupon } }, [
            _vm._v("Apply")
          ]),
          _vm._v(" "),
          _c("md-button", { on: { click: _vm.hide } }, [_vm._v("Cancel")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }