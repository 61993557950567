var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-field",
    { class: { "md-invalid": _vm.error } },
    [
      _c("label", [
        _vm._v(_vm._s(_vm.label)),
        _vm.required ? _c("span", [_vm._v("*")]) : _vm._e()
      ]),
      _vm._v(" "),
      _vm.mask
        ? _c("md-input", {
            directives: [
              {
                name: "mask",
                rawName: "v-mask",
                value: _vm.mask,
                expression: "mask"
              }
            ],
            attrs: {
              placeholder: _vm.placeholder,
              disabled: _vm.disabled,
              name: _vm.name || _vm.label,
              type: _vm.type
            },
            on: { input: _vm.onInput },
            model: {
              value: _vm.inputValue,
              callback: function($$v) {
                _vm.inputValue = $$v
              },
              expression: "inputValue"
            }
          })
        : _c("md-input", {
            attrs: {
              placeholder: _vm.placeholder,
              disabled: _vm.disabled,
              name: _vm.name || _vm.label,
              type: _vm.type
            },
            on: { input: _vm.onInput },
            model: {
              value: _vm.inputValue,
              callback: function($$v) {
                _vm.inputValue = $$v
              },
              expression: "inputValue"
            }
          }),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _c("span", { staticClass: "md-error" }, [
        _vm._v(_vm._s(_vm.error || _vm.errors.first(_vm.name || _vm.label)))
      ]),
      _vm._v(" "),
      _vm.hint
        ? _c("span", { staticClass: "md-helper-text" }, [
            _vm._v(_vm._s(_vm.hint))
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }